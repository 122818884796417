export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
    authorizedUsers: ['user'],
  },
  {
    title: 'Profiles',
    route: 'profiles',
    icon: 'UserIcon',
    authorizedUsers: ['user'],
  },
  {
    title: 'Media Storage',
    route: 'media-storage',
    icon: 'ImageIcon',
    authorizedUsers: ['user'],
  },
  {
    title: 'All Users',
    route: 'users',
    icon: 'UserIcon',
    authorizedUsers: ['admin'],
  },
  {
    title: 'Custom Views',
    route: 'custom-views',
    icon: 'UserIcon',
    authorizedUsers: ['admin'],
  },
  {
    title: 'User Target Ads',
    route: 'user-target-ads',
    icon: 'GlobeIcon',
    authorizedUsers: ['admin'],
  },
  {
    title: 'Devices',
    route: 'devices',
    icon: 'TrelloIcon',
    authorizedUsers: ['user'],
  },
  {
    title: 'Banner Ads',
    route: 'banner-ads',
    icon: 'BookIcon',
    authorizedUsers: ['user'],
  },
  {
    title: 'Target Ads',
    route: 'target-ads',
    icon: 'GlobeIcon',
    children: [
      {
        title: 'View',
        route: 'target-ads',
      },
      {
        title: 'Add new',
        route: 'target-ads-create',
      },
    ],
    authorizedUsers: ['user'],
  },
]
